@import 'fonts';
@import 'colors';
@import 'breakpoints';

.g-cms-content {
	font-size: 2rem;
	color: $colorTextBlue;
	letter-spacing: -0.4px;
	line-height: 1.75;

	strong, b {
		display: block;
		font-weight: bold;
		letter-spacing: -0.5px;
	}

	&:not(.g-cms-content--page) {

		ul {
			list-style-type: none;
			padding: 0;
			margin: 1em 0;
		}

		li {
			color: $colorTextBlueLight;
			letter-spacing: -0.5px;
			background: url('/assets/imgs/bullet-plus.svg') no-repeat left 0.5rem;
			background-size: 2.4rem;
			padding-left: 5rem;
			font-weight: normal;
			margin-bottom: 0.5em;
		}

	}


	h2 {
		font-weight: $weightBlack;
		font-size: 2.4rem;
		line-height: 4.4rem;
		margin-top: 2em;
		margin-bottom: 1em;
		color: $colorTextBlue;
	}
	h3 {
		font-weight: $weightBlack;
		color: $colorTextBlue;
		font-size: 2rem;
		line-height: 4rem;
		margin-top: 2em;
		margin-bottom: 1em;
	}
	h4 {
		font-weight: $weightBlack;
		color: $colorTextBlue;
		font-size: 1.8rem;
		line-height: 3.6rem;
		margin-top: 2em;
		margin-bottom: 1em;
	}
}

.g-cms-content--page {
	font-weight: $weightBook;
	font-size: 1.6rem;
	color: $colorTextBlack;
	line-height: 2;

	strong, b {
		display: inline;
	}

	a {
		text-decoration: underline;

		&:hover {
			color: $colorTextBlueLight;
		}
	}

	table {

		border-spacing: 0;

		td {
			padding: 0.5rem 0.8rem;
		}

		p {
			margin: 0;
		}

		tr:nth-child(even) {
			td {
				background-color: rgba(0, 0, 0, 0.06);
			}
		}
	}

}

.g-cms-content--small {

	font-weight: $weightBook;
	font-size: 1.6rem;
	line-height: 2;

}


@media only screen and (max-width: $breakpointSm) {

	.g-cms-content {
		font-size: 1.6rem;
	}

	.g-cms-content--small {
		font-size: 1.4rem;
	}

}
