@import 'colors';

.g-blue-button {

	display: inline-block;
	color: white;
	font-weight: bold;
	font-size: 1.4rem;
	letter-spacing: 0.5px;
	line-height: 1.8rem;
	position: relative;
	border: none;
	border-radius: 0;
	appearance: none;
	perspective: 50rem;
	padding: 0;
	background: none;
	cursor: pointer;

	.g-blue-button__content	{
		display: block;
		position: relative;
		background-color: $colorTextBlueLight;
		box-sizing: border-box;
		padding: 1.6rem 3rem;
		z-index: 2;
		transition: all 0.3s;
		transform-origin: right top;
		line-height: 2rem;
	}

	&::before {
		content: '';
		display: block;
		z-index: 1;
		background-color: $colorTextBlueLighter;
		width: 4rem;
		height: 4rem;
		position: absolute;
		left: -2.4rem;
		top: -2rem;
		transform-origin: left top;
		transform: scale(1.2) translateZ(-5rem) translate(-1.4rem, -0.3rem) skew(36deg) rotateZ(17deg);
		transition: all 0.3s;
	}

	&:hover {

		&::before {
			background-color: lighten($colorTextBlueLighter, 5%);
			transform: scale(1.15) translateZ(-5rem) translate(-1.4rem, -0.3rem) rotateY(30deg) skew(36deg) rotateZ(17deg);
		}

		.g-blue-button__content {
			background-color: lighten($colorTextBlueLight, 8%);
			transform: rotateY(-6deg) rotateX(5deg);
		}

	}


	&.g-blue-button--white {
		color: $colorBlue;

		.g-blue-button__content {
			background-color: white;
		}

		&::before {
			background: linear-gradient(to bottom, rgba(white, 0.6), rgba(white, 0.3));
		}

	}



}

.g-blue-button--with-blur {
	&::after {
		content: '';
		display: block;
		pointer-events: none;
		background: url('/assets/imgs/blurry-thing.png') center center no-repeat;
		background-size: contain;
		width: 20rem;
		height: 20rem;
		position: absolute;
		left: -10rem;
		top: -12rem;
		opacity: 0.7;
		transition: all 0.3s;
	}

	&:hover {
		&::after {
			transform: scale(1.2);
			opacity: 0.5;
		}
	}
}


.g-blue-button--with-arrow {

	.g-blue-button__content {
		padding-right: 7.5rem;

		&::after {
			content: '';
			display: block;
			position: absolute;
			right: 2.5rem;
			height: 1rem;
			width: 2.5rem;
			top: calc(50% - 0.5rem);
			transition: all 0.3s;
		}
	}

	&:hover {
		.g-blue-button__content::after {
			transform: translateX(0.6rem);
		}
	}

	&:hover {
		.g-blue-button__content.g-arrow--down::after {
			transform: translateY(0.6rem) rotate(90deg) ;
		}
	}

}
