ul.g-list-nelist {

	padding-block-start: 0;
	padding-inline-start: 0;
	margin-block-start: 0;
	margin-block-end: 0;
	padding-left: 0;
	padding-right: 0;
	margin-top: 0;
	margin-bottom: 0;

	&, &>li {
		list-style: none;
	}

}
