$colWidth: 7rem;
$gutterWidth: 2.8rem;

$mobileSidePdding: 25px;

$colWidth1: $colWidth * 1 + $gutterWidth * 0;
$colWidth2: $colWidth * 2 + $gutterWidth * 1;
$colWidth3: $colWidth * 3 + $gutterWidth * 2;
$colWidth4: $colWidth * 4 + $gutterWidth * 3;
$colWidth5: $colWidth * 5 + $gutterWidth * 4;
$colWidth6: $colWidth * 6 + $gutterWidth * 5;
$colWidth7: $colWidth * 7 + $gutterWidth * 6;
$colWidth8: $colWidth * 8 + $gutterWidth * 7;
$colWidth9: $colWidth * 9 + $gutterWidth * 8;
$colWidth10: $colWidth * 10 + $gutterWidth * 9;
$colWidth11: $colWidth * 11 + $gutterWidth * 10;
$colWidth12: $colWidth * 12 + $gutterWidth * 11;


$outWidth1: $colWidth * 1 + $gutterWidth * 1;
$outWidth2: $colWidth * 2 + $gutterWidth * 2;
$outWidth3: $colWidth * 3 + $gutterWidth * 3;
$outWidth4: $colWidth * 4 + $gutterWidth * 4;
$outWidth5: $colWidth * 5 + $gutterWidth * 5;
$outWidth6: $colWidth * 6 + $gutterWidth * 6;
$outWidth7: $colWidth * 7 + $gutterWidth * 7;
$outWidth8: $colWidth * 8 + $gutterWidth * 8;
$outWidth9: $colWidth * 9 + $gutterWidth * 9;
$outWidth10: $colWidth * 10 + $gutterWidth * 10;
$outWidth11: $colWidth * 11 + $gutterWidth * 11;
$outWidth12: $colWidth * 12 + $gutterWidth * 12;
