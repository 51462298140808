@font-face{
	font-family:"Clan"; // Bold
	font-weight: 700;
	font-style: normal;
	src:url("/assets/fonts/5476660/487435bb-c95d-4202-bf68-b10ac86caacc.eot?#iefix");
	src:url("/assets/fonts/5476660/487435bb-c95d-4202-bf68-b10ac86caacc.eot?#iefix") format("eot"),url("/assets/fonts/5476660/abe710ba-a0cb-4b37-b42f-d2a5265c1714.woff2") format("woff2"),url("/assets/fonts/5476660/4fb3a5df-f261-4908-845c-dfd769d600f3.woff") format("woff"),url("/assets/fonts/5476660/39c0d73c-c9da-495a-8afc-53df8e0f9a0d.ttf") format("truetype");
	font-display: swap;
}
@font-face{
	font-family:"Clan"; // Book
	font-weight: 200;
	font-style: normal;
	src:url("/assets/fonts/5476782/fc7e4215-0c9c-4343-a77f-9dec318ec520.eot?#iefix");
	src:url("/assets/fonts/5476782/fc7e4215-0c9c-4343-a77f-9dec318ec520.eot?#iefix") format("eot"),url("/assets/fonts/5476782/241d0d50-842d-4bf4-8cf4-6b102ade9e35.woff2") format("woff2"),url("/assets/fonts/5476782/ec02f579-c26f-4cb0-bd46-db74d4b8149d.woff") format("woff"),url("/assets/fonts/5476782/2007326b-57a9-413f-a55a-1dd005af561a.ttf") format("truetype");
	font-display: swap;
}
@font-face{
	font-family:"Clan"; // Medium
	font-weight: 400;
	font-style: normal;
	src:url("/assets/fonts/5476804/78edc7f2-c2ba-4b33-965f-ca9e3c17d3d4.eot?#iefix");
	src:url("/assets/fonts/5476804/78edc7f2-c2ba-4b33-965f-ca9e3c17d3d4.eot?#iefix") format("eot"),url("/assets/fonts/5476804/98ec963d-bc17-4e18-83b6-ca981a9baab9.woff2") format("woff2"),url("/assets/fonts/5476804/b78d5584-751b-4c61-9efb-21062462a39e.woff") format("woff"),url("/assets/fonts/5476804/fa8c6c5f-0e44-490a-8cc6-e87cc2ca7dc9.ttf") format("truetype");
	font-display: swap;
}
@font-face{
	font-family:"Clan"; // Book Narrow
	font-style: normal;
	font-weight: 100;
	src:url("/assets/fonts/5477010/706e50e5-1bc0-49e7-b23b-752d5bf508c0.eot?#iefix");
	src:url("/assets/fonts/5477010/706e50e5-1bc0-49e7-b23b-752d5bf508c0.eot?#iefix") format("eot"),url("/assets/fonts/5477010/8cc6079d-91aa-48b2-a432-bf95f0ee330b.woff2") format("woff2"),url("/assets/fonts/5477010/c946209c-047f-44ad-86f7-7d1593ef88cf.woff") format("woff"),url("/assets/fonts/5477010/e37ad7fb-9991-4a03-be16-b3196eea4ef8.ttf") format("truetype");
	font-display: swap;
}
@font-face{
	font-family:"Clan"; // Narrow black
	font-weight: 800;
	src:url("/assets/fonts/5477030/2142c836-40e8-4186-912f-4906ff40b895.eot?#iefix");
	src:url("/assets/fonts/5477030/2142c836-40e8-4186-912f-4906ff40b895.eot?#iefix") format("eot"),url("/assets/fonts/5477030/517682f8-29a7-4d37-8b49-678448ae6324.woff2") format("woff2"),url("/assets/fonts/5477030/c8724447-6aee-44bc-8588-bf0162f405be.woff") format("woff"),url("/assets/fonts/5477030/779ac9ee-072a-40a2-9599-b548b6b91c56.ttf") format("truetype");
	font-display: swap;
}
