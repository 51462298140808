.g-arrow {
	&::after {
		content: '';
		display: inline-block;
		background: center center no-repeat url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzEiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAzMSAxMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNLjkgNi40Qy42IDYuNC40IDYuMy4zIDZhLjguOCAwIDAgMS0uMy0uNmMwLS4yIDAtLjQuMy0uNi4xLS4yLjMtLjMuNi0uM2gyNi43bC0zLjItMy4xYS44LjggMCAwIDEtLjMtLjZjMC0uMy4xLS41LjMtLjdBLjguOCAwIDAgMSAyNSAwaC40bC4yLjJMMzAuNCA1bC42LjYtNS40IDUuM2ExIDEgMCAwIDEtLjYuMmMtLjIgMC0uNCAwLS42LS4yYS44LjggMCAwIDEtLjMtLjdsLjMtLjYgMy4yLTMuMUguOXoiIGZpbGw9IndoaXRlIiAvPjwvc3ZnPg==);
		background-size: contain;
		width: 100%;
		height: 100%;
		vertical-align: middle;
	}
}

.g-arrow--blue {
	&::after {
		background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzEiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAzMSAxMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNLjkgNi40Qy42IDYuNC40IDYuMy4zIDZhLjguOCAwIDAgMS0uMy0uNmMwLS4yIDAtLjQuMy0uNi4xLS4yLjMtLjMuNi0uM2gyNi43bC0zLjItMy4xYS44LjggMCAwIDEtLjMtLjZjMC0uMy4xLS41LjMtLjdBLjguOCAwIDAgMSAyNSAwaC40bC4yLjJMMzAuNCA1bC42LjYtNS40IDUuM2ExIDEgMCAwIDEtLjYuMmMtLjIgMC0uNCAwLS42LS4yYS44LjggMCAwIDEtLjMtLjdsLjMtLjYgMy4yLTMuMUguOXoiIGZpbGw9IiMwMDcxQTYiIC8+PC9zdmc+);
	}
}

.g-arrow--black {
	&::after {
		background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzEiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAzMSAxMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNLjkgNi40Qy42IDYuNC40IDYuMy4zIDZhLjguOCAwIDAgMS0uMy0uNmMwLS4yIDAtLjQuMy0uNi4xLS4yLjMtLjMuNi0uM2gyNi43bC0zLjItMy4xYS44LjggMCAwIDEtLjMtLjZjMC0uMy4xLS41LjMtLjdBLjguOCAwIDAgMSAyNSAwaC40bC4yLjJMMzAuNCA1bC42LjYtNS40IDUuM2ExIDEgMCAwIDEtLjYuMmMtLjIgMC0uNCAwLS42LS4yYS44LjggMCAwIDEtLjMtLjdsLjMtLjYgMy4yLTMuMUguOXoiIGZpbGw9ImJsYWNrIiAvPjwvc3ZnPg==);
	}
}


.g-arrow--left {
	&:after {
		transform: scaleX(-1);
	}
}

.g-arrow--up {
	&:after {
		transform: rotate(-90deg);
	}
}

.g-arrow--down {
	&:after {
		transform: rotate(90deg);
	}
}
