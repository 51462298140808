@import 'src/scss/grid';
@import 'src/scss/breakpoints';

.g-container {
	width: $colWidth12;
	margin-left: auto;
	margin-right: auto;
}

@media only screen and (max-width: $breakpointMd) {

	.g-container {
		width: 100%;
		max-width: 100%;
		padding-left: $mobileSidePdding;
		padding-right: $mobileSidePdding;
		box-sizing: border-box;
	}

}
