/* You can add global styles to this file, and also import other style files */

@import '~normalize.css';

@import 'scss/base/base';
@import 'scss/base/fonts';
@import 'scss/base/responsive';

@import 'scss/globals/container';
@import 'scss/globals/lists';
@import 'scss/globals/button';
@import 'scss/globals/blue-button';
@import 'scss/globals/dotted-bg';
@import 'scss/globals/arrow';
@import 'scss/globals/underlined-link';
@import 'scss/globals/blue-label';
@import 'scss/globals/cms-content';

