.g-underlined-link {
	text-decoration: none;
	position: relative;
	display: inline-block;

	&::after {
		display: block;
		content: '';
		position: absolute;
		height: 1px;
		border-bottom: solid 1px currentColor;
		transition: transform 0.3s;
		left: var(--underlined-link-side-margin, 1rem);
		right: var(--underlined-link-side-margin, 1rem);
		bottom: var(--underlined-link-offset, -0.3rem);
		transform-origin: right center;
		transform: scaleX(0)
	}

}

.g-underlined-link:hover, .g-underlined-link--active {
	&::after {
		transform-origin: left center;
		transform: scaleX(1);
	}
}
