@import '../breakpoints';



// Small desktop / wide tablet size

@media only screen and (min-width: $breakpointMd) {

	html, body {
		font-size: 10px * $ratioMd;
		--spacing-ratio: 1;
	}

}


// Basic desktop size

@media only screen and (min-width: $breakpointLg) and (min-height: 750px) {

	html, body {
		font-size: 10px * $ratioLg;
		--spacing-ratio: 1;
	}

}


// Mega size

@media only screen and (min-width: $breakpointXl) and (min-height: 980px) {

	html, body {
		font-size: 10px * $ratioXl;
		--spacing-ratio: 1;
	}

}


// Tablet size

@media only screen and (min-width: $breakpointSm) and (max-width: $breakpointMd) {

	html, body {
		font-size: 10px;
		--spacing-ratio: 1;
	}

}

// Mobile size

@media only screen and (max-width: $breakpointSm) {

	html, body {
		font-size: 10px;
		--spacing-ratio: 0.75;
	}

}
