@import '../fonts';

html, body {
	// font-size: 10px; // Size defined in responsive.scss
	margin: 0;
	padding: 0;
	font-family: $font;
	min-height: 100vh;
}


a {
	color: inherit;
	text-decoration: none;
}


img {
	max-width: 100%;
}
