@import 'colors';

.g-blue-label {
	display: inline-block;
	font-size: 1.2rem;
	color: $colorTextBlue;
	background: rgba(38,134,179,0.08);
	border-radius: 0.5rem;
	padding: 0.7rem 1.4rem;
	margin-right: 0.5rem;
	margin-bottom: 0.5rem;
	border: 1px solid rgba(38,134,179,0);
}

.g-blue-label--white:not(.g-blue-label--blue) {
	border: 1px solid #DEDEDE;
	background: none;
	color: $colorTextBlack;
}

button.g-blue-label {
	cursor: pointer;
	transition: all 0.3s;

	&:hover {
		background: rgba(38,134,179,0.13);
		color: $colorTextBlack;
	}
}

button.g-blue-label--white:not(.g-blue-label--blue) {

	&:hover {
		border-color: #bbb;
		color: black;
		background: none;
	}
}
